import React, { useEffect, useState } from "react";
import "./doctors.css";
import axios from "axios";
import { hospitalCode, serverLink } from "../../resources/url";
import DataLoader from "../common/dataLoader/dataLoader";
import { setServiceDetails } from "../../actions/setactiondetails";
import { connect } from "react-redux";
import {Link, useParams} from "react-router-dom";
import {decryptData, encryptData, shuffle} from "../../resources/constants";
import doctors from "./doctors";
function DoctorDetails(props) {

    const { slug } = useParams();
    if (slug === "") window.location.href = '/';

    const [isLoading, setIsLoading] = useState();
    const [Doctor, setDoctor] = useState(props.DoctorsList !== undefined ? props.DoctorsList : "");

    const getData = async () => {
        await axios.get(`${serverLink}user/website/all/${hospitalCode}`).then((result) => {
            const data = result.data;
            if (data.length > 0) {
                const tt = data.filter(x => x.userID === decryptData(slug))[0]
                setDoctor(tt)
            }
        }).catch((error) => {
            console.log("error", error);
        });
        setIsLoading(false);
    };

    const scrollTop = async () => {
        await window.scrollTo(0, 0);
    };
    useEffect(() => {
        getData();
        scrollTop();
    }, [""]);



    return (

        isLoading ? <DataLoader /> :
            <>
                <section id="appointmentPage">
                    <div className="w-100 page-title" style={{ background: 'none', position: 'relative', zIndex: '0' }}>
                        <div className="container text-white">
                            <h2>Doctor</h2>
                        </div>
                        <div className="w-100 backstretch"
                            style={{ left: '0px', top: '0px', overflow: 'hidden', margin: '0px', padding: ' 0px', height: '159px', zIndex: '-999998', position: 'absolute', }}>
                            <img
                                src="https://cdn.vanguardngr.com/wp-content/uploads/2022/10/Nigerian-Doctors-1024x538.jpg"
                                style={{ position: 'absolute', margin: '0px', padding: '0px', border: 'none', width: '1903px', height: '807.586px', maxHeight: 'none', maxWidth: 'none', zIndex: '-999999', left: '0px', top: '0px', }} />
                        </div>
                    </div>
                    <div className="container bg-white fade-in-right customWidth " style={{ marginTop: '-8px' }}>
                        <div className="col-md-12">
                            <div className="breadcrumb">
                                <div className="container">
                                    <a href="/">Home</a>
                                    <span className="fa fa-angle-right" /> <a href="/Doctor">Doctor</a>
                                    <span className=" fa fa-angle-right" /> <span className="breadcrumb_last">{Doctor.title}. {Doctor.firstName} {Doctor.surname}  {Doctor.middleName}</span>
                                </div>
                            </div>
                            {
                                Doctor !== "" &&
                                <div className="row">
                                    <div className="col-md-4">
                                        {
                                            Doctor.passport  ?
                                                <img className="img-thumbnail"
                                                    src={Doctor.passport}
                                                    alt="Facility"
                                                    style={{ maxHeight: '380px'}} />
                                                :
                                                <img className="img-thumbnail"
                                                    src="https://www.iconpacks.net/icons/1/free-doctor-icon-313-thumb.png"
                                                    alt="Facility"
                                                    style={{ maxHeight: '380px', borderRadius: '50%' }} />

                                        }
                                    </div>
                                    <div className="col-md-6 mb-5">
                                        <h2 style={{ textAlign: 'left' }}>{Doctor.title}. {Doctor.firstName} {Doctor.surname}  {Doctor.middleName} </h2>
                                        <div style={{marginTop:'50px'}}>
                                            <hr />
                                            {
                                                Doctor.aboutDoctor !== undefined ?
                                                    <p className="mt-3" style={{ textAlign: 'justify' }} dangerouslySetInnerHTML={{ __html: Doctor.aboutDoctor }} />
                                                    :
                                                    <h4>No Profile</h4>
                                            }
                                            <hr />
                                            <div className="row">
                                                <div style={{ marginTop: '12px', marginBottom: '12px', display: 'flex' }} >
                                                    {
                                                        Doctor.twitter !== undefined &&
                                                        <a target="_blank" href={Doctor.twitter} style={{ textDecoration: 'none' }}>
                                                            <img src="https://img.icons8.com/color/32/000000/facebook-new.png" />
                                                        </a>
                                                    }

                                                    {
                                                        Doctor.linkedin !== undefined &&
                                                        <a target="_blank" href={Doctor.linkedin} style={{ textDecoration: 'none' }}>
                                                            <img src="https://img.icons8.com/color/32/000000/linkedin-circled--v1.png" />
                                                        </a>
                                                    }
                                                    {
                                                        Doctor.researchGate !== undefined &&
                                                        <a target="_blank" href={Doctor.researchGate} style={{ textDecoration: 'none' }}>
                                                            <img width={'26px'} style={{ borderRadius: '50%', marginTop: '2px' }} src="https://img.icons8.com/external-tal-revivo-color-tal-revivo/32/48/000000/external-researchgate-a-social-networking-site-for-scientists-and-researchers-to-share-papers-logo-color-tal-revivo.png" />&nbsp;
                                                        </a>
                                                    }

                                                    {
                                                        Doctor.academia !== undefined &&
                                                        <a target="_blank" href={Doctor.academia} style={{ textDecoration: 'none' }}>
                                                            <img width={'26px'} style={{ borderRadius: '50%', marginTop: '2px' }} src="https://img.icons8.com/external-tal-revivo-shadow-tal-revivo/48/000000/external-academia-edu-online-teaching-and-learning-website-logo-shadow-tal-revivo.png" />
                                                        </a>
                                                    }

                                                    {
                                                        Doctor.googleScholar !== undefined &&
                                                        <a target="_blank" href={Doctor.googleScholar} style={{ textDecoration: 'none' }}>
                                                            <img src="https://img.icons8.com/color/32/000000/google-scholar--v3.png" />
                                                        </a>
                                                    }



                                                </div>
                                                <Link className="btn btn-lg btn-secondary opacity-75 w-100 " onClick={() => {
                                                    // props.setOnDoctorDetails(item)
                                                }} to={`/doctors-schedule/${encryptData(Doctor.userID)}`}>
                                                    View Schedule</Link>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            }
                        </div>
                    </div>
                </section>
            </>
    )
}
const mapStateToProps = (state) => {
    return {
        DoctorsList: state.DoctorsList,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setOnServiceDetails: (p) => {
            dispatch(setServiceDetails(p));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(DoctorDetails);
