import React, {useEffect, useState} from "react";
import "./doctor-schedule.css";
import DepartmentCard from "./sections/department-card";
import axios from "axios";
import {hospitalCode, serverLink} from "../../resources/url";
import DataLoader from "../common/dataLoader/dataLoader";
function DoctorSchedule() {

    const [isLoading, setIsLoading] = useState(true);
    const [departments, setDepartment] = useState([]);

    const getData = async () => {
        await axios.get(`${serverLink}specialisation/website/all/${hospitalCode}`).then((result) => {
            const data = result.data;
            if (data.length > 0){
                setDepartment(data)
            }
        }).catch((error) => {
            console.log("error", error);
        });
        setIsLoading(false);
    };
    useEffect(() => {
        getData();
    }, [""]);


    return (
        isLoading ? <DataLoader /> :
        <>
            <section id="appointmentPage">
                <div className="container department-list fade-in-right customWidth ">
                    <div className="row">
                        <header className="header" >
                            <h2>Doctor Schedule</h2>
                            <div className="sub_heading">
                                <hr />
                                <p>Select Department</p>
                                <hr />
                            </div>
                        </header>
                        <div className="our-services department-1-col-3">
                            {
                                departments.length > 0 ? departments.map((item, index)=>{
                                    return(
                                        <DepartmentCard key={index} name={item.specialisationName} icon="fa fa-stethoscope" desc="Vestibulum maximus ipsum sed nulla dictum ullamcorper..." btnName="View"/>
                                    )
                                })
                                    :
                                    <div className="col-md-12 alert alert-danger">
                                        <h2>No Record Found</h2>
                                    </div>
                            }
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default DoctorSchedule;