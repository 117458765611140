import React, {useEffect, useState} from "react";
import "./services.css";
import axios from "axios";
import {hospitalCode, serverLink} from "../../resources/url";
import DataLoader from "../common/dataLoader/dataLoader";
import ServiceCard from "./sections/service-card";
import {setServiceDetails} from "../../actions/setactiondetails";
import {connect} from "react-redux";
function ServicesPage(props) {

    const [isLoading, setIsLoading] = useState(!(!props.serviceList.length > 0 ));
    const [services, setServices] = useState(props.serviceList.length > 0 ? props.serviceList : []);

    const getData = async () => {
        await axios.get(`${serverLink}service/list/${hospitalCode}`).then((result) => {
            const data = result.data;
            if (data.length > 0){
                setServices(data)
                props.setOnServiceDetails(data);
            }
        }).catch((error) => {
            console.log("error", error);
        });
        setIsLoading(false);
    };
    useEffect(() => {
        getData();
    }, []);



    return (
        isLoading ? <DataLoader /> :
            <>
                <section id="appointmentPage">
                    <div className="container department-list fade-in-right customWidth ">
                        <div className="row">
                            <header className="header" >
                                <h2>Our Services</h2>
                                <div className="sub_heading">
                                    <hr />
                                    <p> Select a service</p>
                                    <hr />
                                </div>
                            </header>
                            <div className="our-services department-1-col-3">
                                {
                                    services.length > 0 ? services.map((item, index)=>{
                                            return(
                                                <ServiceCard key={index} name={item.serviceName} icon="fa fa-microscope" desc={!item.serviceDescription ? "Our team of experts specializes in treatments to help you get back to your normal activities." : item.serviceDescription} btnName="Read More"/>
                                            )
                                        })
                                        :
                                        <div className="col-md-12 alert alert-danger">
                                            <h2>No Record Found</h2>
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                </section>
            </>
    )
}
const mapStateToProps = (state) => {
    return {
        serviceList: state.ServiceDetails,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setOnServiceDetails: (p) => {
            dispatch(setServiceDetails(p));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ServicesPage);
