import React, { useEffect, useState } from "react";
import "./doctors.css";
import axios from "axios";
import { hospitalCode, serverLink } from "../../resources/url";
import DataLoader from "../common/dataLoader/dataLoader";
import ServiceCard from "./doctors-details";
import { setDoctorsList } from "../../actions/setactiondetails";
import { connect } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { encryptData, shuffle } from "../../resources/constants";
function DoctorsList(props) {

    const { slug } = useParams();
    if (slug === "") window.location.href = '/';

    const [isLoading, setIsLoading] = useState('');
    const [doctors, setdoctors] = useState([]);
    const [doctors2, setdoctors2] = useState([]);

    const getData = async () => {
        await axios.get(`${serverLink}user/website/all/${hospitalCode}`).then((result) => {
            const data = result.data;
            if (data.length > 0) {
                setdoctors(data)
                setdoctors2(data)
            }
        }).catch((error) => {
            console.log("error", error);
        });
        setIsLoading(false);
    };

    const scrollTop = async () => {
        await window.scrollTo(0, 0);
    };
    useEffect(() => {
        getData();
        scrollTop();
    }, [""]);

    const onSearch = (e) => {
        let filtered = doctors2.filter(x => x.firstName.toString().toLowerCase().includes(e.target.value.toString().toLowerCase()) ||
            x.surname.toString().toLowerCase().includes(e.target.value.toString().toLowerCase()) ||
            x.middleName.toString().toLowerCase().includes(e.target.value.toString().toLowerCase()) ||
            [...x.specialisation].includes(e.target.value.toString().toLowerCase())
        )
        setdoctors(filtered)
    }

    return (

        isLoading ? <DataLoader /> :
            <>
                <section id="appointmentPage">
                    <div className="w-100 page-title" style={{ background: 'none', position: 'relative', zIndex: '0' }}>
                        <div className="container" style={{ color: 'white' }}>
                            <h2>Doctors</h2>
                        </div>
                        <div className="w-100 backstretch"
                            style={{ left: '0px', top: '0px', overflow: 'hidden', margin: '0px', padding: ' 0px', height: '159px', zIndex: '-999998', position: 'absolute', }}>
                            <img
                                src="https://cdn.vanguardngr.com/wp-content/uploads/2022/10/Nigerian-Doctors-1024x538.jpg"
                                style={{ position: 'absolute', margin: '0px', padding: '0px', border: 'none', width: '1903px', height: '807.586px', maxHeight: 'none', maxWidth: 'none', zIndex: '-999999', left: '0px', top: '0px', }} />
                        </div>
                    </div>
                    <div className="container bg-white fade-in-right customWidth " style={{ marginTop: '-8px' }}>
                        <div className="col-md-12">
                            <div className="d-flex justify-content-between">
                                <div className="breadcrumb">
                                    <div className="container" >
                                        <a href="/">Home</a>
                                        <span className="fa fa-angle-right" /> <a href="/doctors">Doctors</a>
                                        <span className=" fa fa-angle-right" /> <span className="breadcrumb_last">{slug}</span>
                                    </div>
                                </div>
                                <div style={{ paddingTop: '20px' }}>
                                    <input placeholder="search" onChange={onSearch} />
                                </div>
                            </div>
                            <div className="row" style={{ textAlign: 'justify' }}>
                                {
                                    doctors.length > 0 &&
                                    doctors.map((item, index) => {
                                        return (
                                            <div className="col-md-6" key={index} >
                                                <hr />
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <div>
                                                            {
                                                                item.passport  ?
                                                                    <img className="img-thumbnail"
                                                                        src={item.passport}
                                                                        alt="Facility"
                                                                        style={{ maxHeight: '380px', borderRadius: '50%' }} />
                                                                    :
                                                                    <img className="img-thumbnail"
                                                                        src="https://www.iconpacks.net/icons/1/free-doctor-icon-313-thumb.png"
                                                                        alt="Facility"
                                                                        style={{ maxHeight: '380px', borderRadius: '50%' }} />

                                                            }

                                                        </div>
                                                    </div>
                                                    <div className="col-md-9">
                                                        <div className="doc-name">
                                                            <span><strong>{item.title}. {item.firstName} {item.surname} {item.middleName}</strong></span>
                                                            <div>
                                                                <span>{[...item.specialisation.join(", ")]}</span>
                                                            </div>
                                                        </div>
                                                        {
                                                            item.aboutDoctor === "undefined" ?
                                                                <p className="mt-3" style={{ textAlign: 'justify' }} dangerouslySetInnerHTML={{ __html: item.aboutDoctor }} />
                                                                :
                                                                <p className="mt-3" style={{ textAlign: 'justify' }}>
                                                                </p>
                                                        }

                                                        <div className="row">
                                                            <div style={{ marginTop: '12px', marginBottom: '12px', display: 'flex' }} >
                                                                {
                                                                    item.twitter !== undefined &&
                                                                    <a target="_blank" href={item.twitter} style={{ textDecoration: 'none' }}>
                                                                        <img src="https://img.icons8.com/color/32/000000/facebook-new.png" />
                                                                    </a>
                                                                }

                                                                {
                                                                    item.linkedin !== undefined &&
                                                                    <a target="_blank" href={item.linkedin} style={{ textDecoration: 'none' }}>
                                                                        <img src="https://img.icons8.com/color/32/000000/linkedin-circled--v1.png" />
                                                                    </a>
                                                                }
                                                                {
                                                                    item.researchGate !== undefined &&
                                                                    <a target="_blank" href={item.researchGate} style={{ textDecoration: 'none' }}>
                                                                        <img width={'26px'} style={{ borderRadius: '50%', marginTop: '2px' }} src="https://img.icons8.com/external-tal-revivo-color-tal-revivo/32/48/000000/external-researchgate-a-social-networking-site-for-scientists-and-researchers-to-share-papers-logo-color-tal-revivo.png" />&nbsp;
                                                                    </a>
                                                                }

                                                                {
                                                                    item.academia !== undefined &&
                                                                    <a target="_blank" href={item.academia} style={{ textDecoration: 'none' }}>
                                                                        <img width={'26px'} style={{ borderRadius: '50%', marginTop: '2px' }} src="https://img.icons8.com/external-tal-revivo-shadow-tal-revivo/48/000000/external-academia-edu-online-teaching-and-learning-website-logo-shadow-tal-revivo.png" />
                                                                    </a>
                                                                }

                                                                {
                                                                    item.googleScholar !== undefined &&
                                                                    <a target="_blank" href={item.googleScholar} style={{ textDecoration: 'none' }}>
                                                                        <img src="https://img.icons8.com/color/32/000000/google-scholar--v3.png" />
                                                                    </a>
                                                                }

                                                            </div>
                                                        </div>
                                                        <div>
                                                            <Link className="btn btn-lg btn-secondary opacity-75 " onClick={() => {
                                                                props.setOnDoctorDetails(item)
                                                            }} to={`/doctors/${encryptData(item.userID)}`}>
                                                                View Details</Link>

                                                        </div>

                                                    </div>


                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </section>
            </>
    )
}
const mapStateToProps = (state) => {
    return {
        //doctorList: state.ServiceDetails,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setOnDoctorDetails: (p) => {
            dispatch(setDoctorsList(p));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(DoctorsList);
