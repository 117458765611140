import React, {useEffect, useState} from "react";
import "./services.css";
import axios from "axios";
import {hospitalCode, serverLink} from "../../resources/url";
import DataLoader from "../common/dataLoader/dataLoader";
import ServiceCard from "./sections/service-card";
import {setServiceDetails} from "../../actions/setactiondetails";
import {connect} from "react-redux";
import {useLocation, useParams} from "react-router-dom";
import {shuffle} from "../../resources/constants";
function ServiceDetails(props) {

    const { slug } = useParams();
    if (slug === "") window.location.href = '/';
    let location = useLocation();

    const [isLoading, setIsLoading] = useState(!(!props.serviceList.length > 0 ));
    const [services, setServices] = useState(props.serviceList.length > 0 ? props.serviceList : []);

    const getData = async () => {
        await axios.get(`${serverLink}service/list/${hospitalCode}`).then((result) => {
            const data = result.data;
            if (data.length > 0){
                setServices(data)
                props.setOnServiceDetails(data);
            }
        }).catch((error) => {
            console.log("error", error);
        });
        setIsLoading(false);
    };

    const scrollTop = async () => {
       await window.scrollTo(0, 0);
    };
    useEffect(() => {
        getData();
    }, [""]);

    useEffect(() => {
        scrollTop();
    }, [location]);



    return (

        isLoading ? <DataLoader /> :
            <>
                <section id="appointmentPage">
                    <div className="w-100 page-title" style={{ background: 'none', position: 'relative', zIndex: '0'}}>
                        <div className="container">
                            <h2>Services</h2>
                        </div>
                        <div className="w-100 backstretch"
                             style={{left: '0px', top: '0px', overflow: 'hidden', margin: '0px', padding:' 0px', height: '159px',zIndex: '-999998', position: 'absolute',}}>
                            <img
                                src="https://demo2.themewarrior.com/hospitalplus/wp-content/themes/hospitalplus/images/header/header.jpg"
                                style={{position: 'absolute', margin: '0px', padding: '0px', border: 'none',width: '1903px', height: '807.586px', maxHeight: 'none', maxWidth: 'none', zIndex: '-999999', left: '0px', top: '0px',}}/>
                        </div>
                    </div>
                    <div className="container bg-white fade-in-right customWidth " style={{marginTop: '-8px'}}>
                        <div className="col-md-12">
                            <div className="breadcrumb">
                                <div className="container">
                                    <a href="/">Home</a>
                                    <span className="fa fa-angle-right"/> <a href="/services">Services</a>
                                    <span className=" fa fa-angle-right"/> <span className="breadcrumb_last">{slug}</span>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4">
                                    <div>
                                        <img className="img-thumbnail" src="/assets/img/facilities.jpg" alt="Facility" style={{maxHeight: '380px'}}/>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <h2 style={{textAlign: 'left'}}>{slug}</h2>
                                    <p className="mt-3" style={{textAlign: 'justify'}}>
                                        Well we’re movin’ on up, to the east side. To a deluxe apartment in the sky. Movin’ on up, To the east side. We finally got a piece of the pie. Fish don’t fry in the kitchen, beans don’t burn on the grill. Took a whole lotta tryin’ just to get up that hill. Now we’re up in the big leagues, gettin’ our turn at bat. As long as we live, it’s you and me baby, There ain’t nothin’ wrong with that. Well we’re movin’ on up, to the east side. To a deluxe apartment in the sky. Movin’ on up, to the east side. We finally got a piece of the pie.
                                    </p>
                                </div>
                                    <div className="container testimonial-group">
                                        <h4 className="section-title mt-5">Other Services</h4>
                                        <div className="table-responsive-md">
                                            <div className="our-services department-1-col-3 ">
                                                {
                                                    services.length > 0 ? shuffle(services).splice(0,3).map((item, index)=>{
                                                            return(
                                                                <ServiceCard key={index} name={item.serviceName} icon="fa fa-microscope" desc={!item.serviceDescription ? "Our team of experts specializes in treatments to help you get back to your normal activities." : item.serviceDescription} btnName="Read More"/>
                                                            )
                                                        })
                                                        :
                                                        <div className="col-md-12 alert alert-danger">
                                                            <h2>No Record Found</h2>
                                                        </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
    )
}
const mapStateToProps = (state) => {
    return {
        serviceList: state.ServiceDetails,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setOnServiceDetails: (p) => {
            dispatch(setServiceDetails(p));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ServiceDetails);
