import { useState } from "react";
import { Link } from "react-router-dom";
import "./navigationbar.css";

const Section_To_Identifier_Map = {
    home: {
        identifier: "hero-bg-container", type: "class"
    },
    services: {
        identifier: "serviceContainer", type: "class"
    },
    about: {
        identifier: "footerID", type: "id"
    },
    contact: {
        identifier: "appointment-bg-container", type: "class"
    }
}
function scrollToSection(section_identifier) {
    let el;
    if (window !== undefined && window.document !== undefined) {
        const section = Section_To_Identifier_Map[section_identifier];
        if (section && section.type === "class") el = document.getElementsByClassName(section.identifier)[0];
        else if (section && section.type === "id") el = document.getElementById(section.identifier);
        if (el) el.scrollIntoView({ behavior: 'smooth' });
    }
}

export default function NavigationBar() {

    const [isOpen, setIsOpen] = useState(false)
    const toggleDrawer = () => {
        setIsOpen((prevState) => !prevState)
    }
    return (
        <>
            {/* <h2>NAVIGATION HERE</h2> */}
            {/* <button onClick={toggleDrawer}>Show</button> */}
            <div id="normalNavBar" className="navBar">
                <div className="navLogoContainer">
                    <Link to="/" style={{ display: 'flex' }}>
                        <img src={require(`../../../img/BUH Logo.png`)} alt="logo" />
                        <div>
                            <p className="navLogoTitle">Baze University</p>
                            <p className="navLogoSubTitle">Hospital</p>
                        </div>
                    </Link>
                </div>

                <div className="navMenu">
                    <ul>
                        <Link to="/">
                            <li>Home</li>
                        </Link>
                        <Link to="/services">
                            <li>Services</li>
                        </Link>
                        {/*<Link to="/book-an-appointment">*/}
                        {/*    <li>Book An Appointment</li>*/}
                        {/*</Link>*/}
                        <Link to="/doctors">
                            <li>Doctors</li>
                        </Link>
                        <Link to="/schedules">
                            <li>Doctor Schedules</li>
                        </Link>
                        <Link to="/news" >
                            <li>News</li>
                        </Link>
                    </ul>
                </div>

                <div className="navLoginBtn">
                    <p><Link to="/login">Patient login</Link></p>
                    <Link to="/register">
                        <div>
                            <img src={require(`../../../img/navPersonWhite.png`)} alt="logo" />
                            <p>Register Now</p>
                        </div>
                    </Link>
                </div>

            </div>

            {/* RESPONSIVE NAV-BAR */}

            <div id="mobileNavBar" className="navBar">
                <div className="navLogoContainer">
                    <Link to="/" style={{ display: 'flex' }}>
                    <img src={require(`../../../img/BUH Logo.png`)} alt="logo" />
                    </Link>
                    <svg style={{ cursor: "pointer" }} onClick={toggleDrawer} xmlns="http://www.w3.org/2000/svg" fill="#000000" viewBox="0 0 30 30" width="30px" height="30px"><path d="M 3 7 A 1.0001 1.0001 0 1 0 3 9 L 27 9 A 1.0001 1.0001 0 1 0 27 7 L 3 7 z M 3 14 A 1.0001 1.0001 0 1 0 3 16 L 27 16 A 1.0001 1.0001 0 1 0 27 14 L 3 14 z M 3 21 A 1.0001 1.0001 0 1 0 3 23 L 27 23 A 1.0001 1.0001 0 1 0 27 21 L 3 21 z" /></svg>
                    {/* <div>
                        <p className="navLogoTitle">Baze University</p>
                        <p className="navLogoSubTitle">Hospital</p>
                    </div> */}
                </div>

                <div className={isOpen ? "navMenu" : "hideOnScreen"}>
                    <ul>
                        <Link to="/">
                            <li>Home</li>
                        </Link>
                        <Link to="/services">
                            <li>Services</li>
                        </Link>
                        <Link to="/doctors">
                            <li>Doctors</li>
                        </Link>
                        <Link to="/schedules">
                            <li>Doctor Schedules</li>
                        </Link>
                        <Link to="/news" >
                            <li>News</li>
                        </Link>
                    </ul>
                </div>

                <div className={isOpen ? "navLoginBtn" : "hideOnScreen"}>
                    <Link to="/register">
                        <div>
                            <img src={require(`../../../img/navPersonWhite.png`)} alt="logo" />
                            <p>Register Now</p>
                        </div>
                    </Link>
                </div>

            </div>
        </>
    );
}