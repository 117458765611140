import { hospitalCode, serverLink } from "../resources/url";

export const setGeneralDetails = (p) => {
    return {
        type: `SET_${hospitalCode}_GENERAL_DETAILS`,
        payload: p,
    };
};

export const setServiceDetails = (p) => {
    return {
        type: `SET_${hospitalCode}_SERVICE_DETAILS`,
        payload: p,
    };
};

export const setNewsDetails = (p) => {
    return {
        type: `SET_${hospitalCode}_NEWS_DETAILS`,
        payload: p,
    };
};

export const setSliderDetails = (p) => {
    return {
        type: `SET_${hospitalCode}_SLIDER_DETAILS`,
        payload: p,
    };
};

export const setDoctorDetails = (p) => {
    return {
        type: `SET_${hospitalCode}_DOCTOR_DETAILS`,
        payload: p,
    };
};


export const setDoctorsList = (p) => {
    return {
        type: `SET_${hospitalCode}_DOCTORS_LIST`,
        payload: p,
    };
};
