import React, {useEffect, useState} from "react";
import "../doctor-schedule.css";
import DoctorsCard from "../sections/doctors-card";
import { useParams} from "react-router-dom";
import axios from "axios";
import {hospitalCode, serverLink} from "../../../resources/url";
import DataLoader from "../../common/dataLoader/dataLoader";

function DoctorsView() {
    const { slug } = useParams();
    if (slug === "") window.location.href = '/';

    const [isLoading, setIsLoading] = useState(true);
    const [doctors, setDoctors] = useState([]);

    const getData = async () => {
        await axios.get(`${serverLink}user/website/all/${hospitalCode}`).then((result) => {
            const data = result.data;
            if (data.length > 0){
                setDoctors(data.filter(e => e.specialisation.includes(slug)))
            }
        }).catch((error) => {
            console.log("error", error);
        });
        setIsLoading(false);
    };
    useEffect(() => {
        getData();
    }, [""]);


    return (isLoading ? <DataLoader /> :
        <>
            <section id="appointmentPage">
                <div className="container department-list fade-in-right customWidth ">
                    <div className="row">
                        <a  href="javascript: history.go(-1)">
                            <div className="book_appointment_backArrow">
                                <img src="/assets/img/BackArrow.png" alt="back button" />
                                <p>Back</p>
                            </div>
                        </a>

                        <header className="header" >
                            <h2>Doctor Schedule</h2>
                            <div className="sub_heading">
                                <hr />
                                <p>Select Doctor</p>
                                <hr />
                            </div>
                        </header>
                        <div className="our-services department-1-col-3">
                            {
                                doctors.length > 0 ? doctors.map((item, index)=>{
                                        return(
                                            <DoctorsCard key={index} name={`${item.title}. ${item.firstName} ${item.middleName} ${item.surname}`} icon="fa fa-stethoscope" specialisation={slug} doctor={item.userID} btnName="View Schedule"/>
                                        )
                                    })
                                    :
                                    <div className="col-md-12 alert alert-danger">
                                        <h2>No Available Doctors</h2>
                                    </div>
                            }
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default DoctorsView;