

import { useState } from "react";

import "./book-an-appointment.css";

const headings = ["", "Help Us Know You More", "Please Select A Department", "Book Session With A Doctor"];
const colorList = ["#E59500", "#68ACF1", "#BD68F1", "#00BD9D", "#F168AA", "#314E7A", "#A30B37"];

function BookAnAppointment() {

	const [currentStep, setCurrentStep] = useState(1);

	const OnNext = (e, from) => {
		setCurrentStep(from + 1);
	}

	return (
		<section id="appointmentPage">
			{currentStep &&
				<AppointmentPageContentHeader update_step={setCurrentStep} current_step={currentStep} sub_heading={headings[currentStep]}>
					{currentStep === 1 && < AppointmentFormComponent next_step={OnNext} />}
					{currentStep === 2 && < ListOfDepartmentsComponent next_step={OnNext} />}
					{currentStep === 3 && < ListOfAvailableDoctors />}

				</AppointmentPageContentHeader>
			}
		</section>
	)
}
export default BookAnAppointment;



function AppointmentPageContentHeader({ children, sub_heading = "", update_step, current_step }) {

	const OnBackButtonClicked = (e) => {

		update_step(current_step => {
			if (current_step > 1)
				return current_step - 1;
			else {
				return current_step;
			}
		});
	}

	return (
		<main className={"appointmentBody" + (current_step < 2 ? " fade-in-right" : current_step === 2 ? " customWidth" : " customWidth-3")}>
			{current_step > 1 && <div className="book_appointment_backArrow" onClick={OnBackButtonClicked}>
				<img src="/assets/img/BackArrow.png" alt="back button" />
				<p>Back</p>
			</div>}

			<header className="header" >

				<h2>Book An Appointment </h2>
				<div className="sub_heading">
					<hr />
					<p>{sub_heading}</p>
					<hr />
				</div>
			</header>
			{children}
		</main>
	)
}



function ListOfAvailableDoctors() {
	return (
		<section className="doctors">
			<DoctorCardListItem name="Dr.AbdulKareem Aliyu" specialties={["Dentist", "Surgeon"]} />
			<DoctorCardListItem name="Dr.AbdulKareem Aliyu" specialties={["Dentist", "Surgeon"]} />
			<DoctorCardListItem name="Dr.AbdulKareem Aliyu" specialties={["Dentist", "Surgeon"]} />
		</section>
	)
}

function DoctorCardListItem({ name = "", specialties = [] }) {

	return (
		<div className="doctorCard">
			<div className="cardIcon">
				<img src="/assets/img/stethoscope-icon.png" alt="" />
			</div>
			<header>
				<h2> {name} </h2>
				{specialties.map(specialty => <h5> {specialty} </h5>)}
			</header>
			<button onClick={""}>Book Now</button>
		</div>
	)
}


function ListOfDepartmentsComponent({ next_step }) {

	return (
		<section className="departments fade-in-right">

			<DepartmentCardListItem dept_name="General Office Patient Department" color={colorList[0]} next_step={next_step} />
			<DepartmentCardListItem dept_name="Dental" color={colorList[1]} next_step={next_step} />
			<DepartmentCardListItem dept_name="Pysiotherapy" color={colorList[2]} next_step={next_step} />
			<DepartmentCardListItem dept_name="Opthalmology" color={colorList[3]} next_step={next_step} />
			<DepartmentCardListItem dept_name="Pediatrics" color={colorList[6]} next_step={next_step} />
			<DepartmentCardListItem dept_name="Cardiovascular" color={colorList[4]} next_step={next_step} />
			<DepartmentCardListItem dept_name="Gynacology" color={colorList[5]} next_step={next_step} />

		</section>
	)

}


function DepartmentCardListItem({ dept_name, color = "", next_step }) {

	return (
		<div className="appointmentDeptCard" onClick={(e) => next_step(e, 2)}>
			<div style={{ backgroundColor: color }} className="cardIcon">
				<img src="/assets/img/surgeon-female-icon.png" alt="" />
			</div>
			<h2 style={{ color: color }}>{dept_name}</h2>
			<svg
				transform="rotate(90)"
				width="23px"
				height="27px"
				viewBox="0 -0.5 17 17"
				xmlns="http://www.w3.org/2000/svg"
				className="si-glyph si-glyph-triangle-up"
			>
				<path
					d="M7.96 2.392a1.49 1.49 0 012.104 0l6.442 6.444c.582.581.839 2.103-1 2.103H2.518c-1.902 0-1.582-1.521-1.001-2.103L7.96 2.392z"
					fill={color}
					className="si-glyph-fill"
					fillRule="evenodd"
				/>
			</svg>
		</div>
	)
}


function AppointmentFormComponent({ next_step }) {

	return (
		<section className={"appointmentForm fade-in-right"}>

			<div className="split-fields">
				<div className="input-field">
					<label>First Name</label>
					<input type="text" name="first_name" placeholder="Enter your first name" />
				</div>

				<div className="input-field">
					<label>Last Name</label>
					<input type="text" name="first_name" placeholder="Enter your last name" />
				</div>
			</div>

			<div className="split-fields">
				<div className="input-field">
					<label>Email</label>
					<input type="email" name="first_name" placeholder="YourEmail@provider.com" />
				</div>

				<div className="input-field">
					<label>Phone Number</label>
					<input type="text" name="first_name" placeholder="+234 XXXX XXXX" />
				</div>
			</div>

			<button type="submit" className="register-cardBtn" onClick={(e) => next_step(e, 1)}> <h3>Next</h3> </button>

		</section>
	)
}