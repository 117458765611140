import {Link} from "react-router-dom";

function HeroFooterOverlay() {
  return (

    <section id="heroFooterOverlay">
          <div>
            <img src="/assets/img/health-services-icon.svg" width="60" height="60" alt="health-services-icon" />
            <h2 id="hero-h2">Health Services</h2>
            <p id="hero-p">We offer diverse and robust health services</p>
          </div>

      <div id="divider" />

      <div>
        <img src="/assets/img/doctors-icon.svg" width="60" height="60" alt="doctors-icon.svg" />
        <h2 id="hero-h2">Doctors</h2>
        <p id="hero-p">Our Doctors are always available and on call.</p>
      </div>

      <div id="divider" />

      <div>
        <img src="/assets/img/equipment-icons.svg" width="60" height="60" alt="equipment-icons.svg" />
        <h2>Equipment</h2>
        <p>We are well equipped with state of the art medical equipments</p>
      </div>

      <div id="divider" />

      <div>
        <img src="/assets/img/patient-portal-icon.svg" width="60" height="60" alt="patient-portal-icon.svg" />
        <h2>Patient Portals</h2>
        <p>We provide each patient with their own dedicated portal </p>
      </div>

    </section>
  )
}

export default HeroFooterOverlay