import "./news.css";
import { Link } from 'react-router-dom';
import NewsCard from "./newsCard";

function News(props) {
	return (
		<section className="newsContainer">
			<div className="sectionHeader">
				<p className="sectionIntro">- News</p>
				<div className="sectionBody">
					<h1 className="sectionBodyTitle">Read Our <span>Latest</span> News</h1>
					<p className="sectionBodyNote">We offer quality and roburts health services</p>
				</div>
			</div>

			<section className="newsCardContainer">
				
				{
					props.newsList.length > 0 && props.newsList.sort((a,b)=> a.submittedOn - b.submittedOn).slice(0,4).sort(()=> Math.random() - 0.4).map((items, index)=>{
						return(
							<NewsCard key={index} _link={items.hospitalCode} _image={items.image} _date={items.submittedOn} _description={items.description} _submittedby={items.submittedBy} _title={items.title}/>
						)
					})
				}
			</section>

			<div className="allPlainBtn">
				{/* <p><Link to="/">Staff login</Link></p> */}
				<Link to="/news">
					<div>
						{/* <img src="assets\img\navPersonWhite.png" alt="logo" /> */}
						<p>More News</p>
					</div>
				</Link>
			</div>

		</section>
	)
}

export default News
