import React from "react";
import "./topbar.css";

export default function TopBar() {
    return (
        <>
            {/* <h5>TOP BAR HERE</h5> */}
            <div className="topBar">
                <div className="topContact">
                    <div>
                        <img src={require(`../../../img/topPhone.png`)}  alt="phone icon" />
                        <p><span>Emergency Line: </span>(+234) 813 376 9658</p>
                    </div>
                    <div>
                        <img src={require(`../../../img/topCircledEnvelope.png`)}  alt="email icon" />
                        <p><span>Email: </span>info@bazeuniversityhospital.com</p>
                    </div>
                </div>
                <div className="topSocials">
                    <img src={require(`../../../img/topFacebookCircled.png`)}  alt="Facebook icon" />
                    <img src={require(`../../../img/topInstagram.png`)}  alt="Instagram icon" />
                    <img src={require(`../../../img/topTwitterCircled.png`)}  alt="Twitter icon" />
                </div>
            </div>

        </>
    );
}