import { hospitalCode } from "../resources/url";

export const GeneralDetailsReducer = (state = '', action) => {
    switch (action.type) {
        case `SET_${hospitalCode}_GENERAL_DETAILS`:
            return action.payload;
        default:
            return state;
    }
};

export const ServiceDetailsReducer = (state = [], action) => {
    switch (action.type) {
        case `SET_${hospitalCode}_SERVICE_DETAILS`:
            return action.payload;
        default:
            return state;
    }
};

export const NewsDetailsReducer = (state = [], action) => {
    switch (action.type) {
        case `SET_${hospitalCode}_NEWS_DETAILS`:
            return action.payload;
        default:
            return state;
    }
};

export const SliderDetailsReducer = (state = [], action) => {
    switch (action.type) {
        case `SET_${hospitalCode}_SLIDER_DETAILS`:
            return action.payload;
        default:
            return state;
    }
};

export const DoctorDetailsReducer = (state = [], action) => {
    switch (action.type) {
        case `SET_${hospitalCode}_DOCTOR_DETAILS`:
            return action.payload;
        default:
            return state;
    }
};


export const DoctorsListReducer = (state = [], action) => {
    switch (action.type) {
        case `SET_${hospitalCode}_DOCTORS_LIST`:
            return action.payload;
        default:
            return state;
    }
};