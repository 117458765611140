import "./homepage.css"

import Service from "./sections/service/service";
import Appointment from "./sections/appointment/appointment";
import Testimonial from "./sections/testimonial/testimonial";
import News from "./sections/news/news";
import Download from "./sections/download/download";
import HeroFooterOverlay from "./sections/hero/heroFooterOverlay";
import Hero from "./sections/hero/hero";
import { connect } from "react-redux";
import Footer from "../common/footer/footer";
import {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import {setNewsDetails, setServiceDetails} from "../../actions/setactiondetails";
import axios from "axios";
import {hospitalCode, serverLink} from "../../resources/url";
import DataLoader from "../common/dataLoader/dataLoader";


function Homepage(props) {
    const [isLoading, setIsLoading] = useState(!(!props.serviceList.length > 0 && props.newsList.length > 0 ));
    const [news, setNews] = useState(props.newsList.length > 0 ? props.newsList : []);
    const [services, setServices] = useState(props.serviceList.length > 0 ? props.serviceList : []);

    const getHomepageData = async () => {
        await axios.get(`${serverLink}service/list/${hospitalCode}`).then((result) => {
            const data = result.data;
            if (data.length > 0){
                setServices(data)
                props.setOnServiceDetails(data);
            }

        }).catch((error) => {
            console.log("error", error);
        });



        await axios.get(`${serverLink}website/homepage/news/active/${hospitalCode}`).then((result) => {
            const data = result.data;
            if (data.length > 0){
                setNews(data)
                props.setOnNewsDetails(data);
            }
            setIsLoading(false);
        }).catch((error) => {
            console.log("error", error);
        });

    };
    useEffect(() => {
        getHomepageData();
    }, [""]);


    return (
        isLoading ? <DataLoader/> :
        <>
            <Hero />
            <HeroFooterOverlay />
            <Service serviceList={props.serviceList} />
            <Appointment />
            <News newsList={props.newsList}/>
            <Footer />
        </>
    );
}
const mapStateToProps = (state) => {
    return {
        serviceList: state.ServiceDetails,
        newsList: state.NewsDetails
    };
};


const mapDispatchToProps = (dispatch) => {
    return {
        setOnServiceDetails: (p) => {
            dispatch(setServiceDetails(p));
        },

        setOnNewsDetails: (p) => {
            dispatch(setNewsDetails(p));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Homepage);

