import axios from "axios";
import React, {useEffect, useState} from "react";
import { connect } from "react-redux";
import {useLocation, useParams} from "react-router-dom";
import DataLoader from "../../../common/dataLoader/dataLoader";
import Sidebar from "./sidebar";
import {serverLink, hospitalCode} from "../../../../resources/url"
import { setNewsDetails } from "../../../../actions/setactiondetails";
import { formatDateAndTime } from "../../../../resources/constants";
import header from "../../../../images/news.jpg"

function NewDetailsContent(props) {
    const params = useParams();
    const title = params.slug
    if (title === "") window.location.href = '/';
    let location =  useLocation();
    const [isLoading, setIsLoading] = useState(!(!props.newsList.length > 0 ));
    const [newsData, setNewsData] = useState(props.newsList.length > 0 ? props.newsList.filter(x => x.title === title) : []);
    const [newsList, setNewsList] = useState(props.newsList.length > 0 ? props.newsList : []);
    const [newsList1, setNewsList1] = useState(props.newsList.length > 0 ? props.newsList : []);
    const [newsList2, setNewsList2] = useState(props.newsList.length > 0 ? props.newsList : []);

    const getData = async () => {
        await axios.get(`${serverLink}website/homepage/news/active/${hospitalCode}`).then((result) => {
            const data = result.data.filter(x => x.title === title);
            setNewsData(data)
            setNewsList(data)
            setNewsList1(result.data)
            setNewsList2(result.data)
            props.setOnNewsDetails(data);
        }).catch((error) => {
            console.log("error", error);
        });
        setIsLoading(false);
    };

    const scrollTop = async () => {
        await window.scrollTo(0, 0);
    };


    useEffect(() => {
        getData();
        scrollTop();
    }, [location]);

    const handleSearch =(e)=>{
        const filtered = newsList2.filter(x=> x.title.toLowerCase().includes(e.target.value.toLowerCase()));
        setNewsList(filtered)
    }

    return (

         isLoading ? <DataLoader /> :
        <>
            <section id="appointmentPage">
                <div className="w-100 page-title" style={{ background: 'none', position: 'relative', zIndex: '0'}}>
                    <div className="container">
                        <h2>News</h2>
                    </div>
                    <div className="w-100 backstretch"
                         style={{left: '0px', top: '0px', overflow: 'hidden', margin: '0px', padding:' 0px', height: '159px',zIndex: '-999998', position: 'absolute',}}>
                        <img
                            src={header}
                            style={{position: 'absolute', margin: '0px', padding: '0px', border: 'none', height: '807.586px', maxHeight: 'none', maxWidth: 'none', zIndex: '-999999', left: '0px', top: '0px',}}/>
                    </div>
                </div>
                <div className="container bg-white fade-in-right customWidth " style={{marginTop: '-8px'}}>
                    <div className="col-md-12">
                        {
                                newsData.length > 0 ? (<>
                        <div className="breadcrumb">
                            <div className="container">
                                <a href="/">Home</a>
                                <span className="fa fa-angle-right"/> <a href="/news">News</a>
                                <span className=" fa fa-angle-right"/> <span className="breadcrumb_last">{newsData.title}</span>
                            </div>
                        </div>
                        <div className="row container">
                            <figcaption className={"mb-3"} style={{textAlign: "justify"}}>
                                <h3>{newsData[0]?.title}</h3>
                            </figcaption>
                            <div className={"col-md-12"}>
                                <div className={"row"}>
                                    <div className={"col-md-2"}>
                                        <figcaption className={"mb-2"} style={{textAlign: "justify"}}>
                                            <img src="/assets/img/news-calender-icon.png" alt="calendar icon" /> <small>{formatDateAndTime(newsData[0]?.submittedOn, "date")}</small>
                                        </figcaption>
                                    </div>
                                    <div className={"col-md-2"}>
                                        <figcaption className={"mb-2"} style={{textAlign: "justify"}}>
                                            <small className={"fa fa-user"}></small> {newsData[0].submittedBy}
                                        </figcaption>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-9 mb-5">
                                <div>
                                    <img className="img-thumbnail" src={newsData[0]?.image} alt="Facility" style={{maxHeight: '380px', width: '100%'}}/>
                                </div>
                                <p className="mt-3" style={{textAlign: 'justify'}}>
                                    { <span  style={{textAlign: 'justify'}} dangerouslySetInnerHTML={{ __html: newsData[0]?.description }} /> }</p>
                            </div>
                            <div className={"col-md-3"}>
                                <Sidebar
                                    newsList1={newsList1}
                                    newsList = {newsList}
                                    handleSearch={handleSearch}
                                />
                            </div>

                        </div>
                        </>):("")
                            }

                    </div>
                </div>
            </section>
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        newsList: state.NewsDetails,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setOnNewsDetails: (p) => {
            dispatch(setNewsDetails(p));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(NewDetailsContent);