import "./bottomBar.css";
import {Link} from "react-router-dom";

function BottomBar() {
	return (
		<footer className="footerBottomBar">
			<p>© Copyright</p> &nbsp;
			<a href="https://staff.bazeuniversityhospital.com"  target="_blank"  style={{color: "#29CCFF"}}>Baze University Hospital.&nbsp;</a>
			<p>All Rights Reserved</p>
		</footer>
	)
}

export default BottomBar