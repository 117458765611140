const status = "Live";
export const serverLink =
    status === "Dev"
        ? "http://localhost:4480/"
        : "https://bazeuniversityhospital-server.herokuapp.com/";

export const simpleFileUploadAPIKey = "305e0ddf925a3fc5dd34d314f8230349";

export const hospitalCode = 'BUH';
export const serverStatus = status;
export const uploadAPI = "305e0ddf925a3fc5dd34d314f8230349";