import './App.css';
import PageRoutes from "./component/pageroutes/pageroutes";
import {useEffect, useState} from "react";
import DataLoader from "./component/common/dataLoader/dataLoader";
import {setServiceDetails, setNewsDetails} from "./actions/setactiondetails";
import {connect} from "react-redux";
import {hospitalCode, serverLink} from "./resources/url";
import axios from "axios";

function App(props) {
  const [isLoading, setIsLoading] = useState(!(!props.serviceList.length > 0 ));

  const getData = async () => {
    await axios.get(`${serverLink}service/list/${hospitalCode}`).then((result) => {
      const data = result.data;
      if (data.length > 0){
        props.setOnServiceDetails(data);
      }
    }).catch((error) => {
      console.log("error", error);
    });
    


    await axios.get(`${serverLink}website/homepage/news/active/${hospitalCode}`).then((result) => {
      const data = result.data;
      if (data.length > 0){
        props.setOnNewsDetails(data);
      }
      setIsLoading(false);
    }).catch((error) => {
      console.log("error", error);
    });
  };
  useEffect(() => {
    getData();
  }, [""]);


  return (
    <div id="AppId" className="App">
      {isLoading ? <DataLoader /> : <PageRoutes />}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    serviceList: state.ServiceDetails,
    newsList: state.NewsDetails
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setOnServiceDetails: (p) => {
      dispatch(setServiceDetails(p));
    },

    setOnNewsDetails: (p) => {
      dispatch(setNewsDetails(p));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(App);
