import "./hero.css";
import { Link } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import axios from "axios";
import { hospitalCode, serverLink } from "../../../../resources/url";
import { useState } from "react";
import { useEffect } from "react";




export default function Hero() {
    const [sliderss, setSliders] = useState([])

    const getData = async () => {
        await axios.get(`${serverLink}website/homepage/slider/all/${hospitalCode}`).then((res) => {
            if (res.data.length > 0) {
                setSliders(res.data)
            }
        })
    }

    useEffect(() => {
        getData()
    }, [""])

    return (
        <>


            <div >
                <div className='hero-bg-container'>
                    <div className='hero-container'>

                        <div className=''>
                            <div className="hero-slider">
                                <Carousel infiniteLoop={true} interval={5000} autoPlay={true} renderThumbs={([]) => { }}>
                                    {
                                        sliderss.length > 0 &&
                                        sliderss.map((x, i) => {
                                            return (
                                                <div key={i}>
                                                    <img src={x.image} style={{ maxHeight: '650px'}} className="slider-image" />
                                                    {
                                                        x.title !== "" &&
                                                        <div className="hero-slider-text text-slider" >

                                                            <div className="">
                                                                <div className="main_text"><h3>{x.title}</h3></div>
                                                                <div >
                                                                    {
                                                                        x.caption !== null ? x.caption : ''
                                                                    }
                                                                </div>
                                                                {
                                                                    x.btnName !== "" &&
                                                                    <div>
                                                                        <a target={"_blank"} href={x.btnLink} className="btn btn-secondary opacity-100" >
                                                                            {x.btnName}
                                                                        </a>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            )
                                        })
                                    }
                                </Carousel>

                            </div>
                            {/* <div className='hero-left-content'>
                                <h1>Your health is our <span style={{color: "#1580EB" }}>top</span> priority</h1>
                                <p>This is a  lorem ipsum paragraph This is a  lorem ipsum paragraph This is a  lorem ipsum paragraph This is a  lorem ipsum paragraph.</p>

                                <div className="heroImagedBtn">
                                    <Link to="/login">
                                        <div>
                                            <img src="assets\img\navPersonWhite.png" alt="logo" />
                                            <p>Login to Patient Account</p>
                                        </div>
                                    </Link>
                                </div>


                            </div>

                            <div className='hero-right-content'>
                                <img src="assets\img\ladyDr.png" alt="" />
                            </div> */}

                        </div>

                    </div>
                </div>

            </div>


        </>
    )
}
