import {hospitalCode} from "../resources/url";
import storage from "redux-persist/lib/storage";
import {combineReducers} from "redux";
import {persistReducer} from "redux-persist";
import {
    DoctorDetailsReducer,
    DoctorsListReducer,
    GeneralDetailsReducer,
    NewsDetailsReducer,
    ServiceDetailsReducer,
    SliderDetailsReducer
} from "./detailsReducer";

const rootReducer = combineReducers({
    GeneralDetails: GeneralDetailsReducer,
    ServiceDetails: ServiceDetailsReducer,
    NewsDetails: NewsDetailsReducer,
    SliderDetails: SliderDetailsReducer,
    DoctorDetails: DoctorDetailsReducer,
    DoctorsList: DoctorsListReducer
});

const persistConfig = {
    key: hospitalCode.toLowerCase(),
    storage,
};

export default persistReducer(persistConfig, rootReducer);