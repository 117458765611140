import React, {useEffect, useState} from "react";
import "./news.css";
import axios from "axios";
import {hospitalCode, serverLink} from "../../resources/url";
import DataLoader from "../common/dataLoader/dataLoader";
import {setNewsDetails} from "../../actions/setactiondetails";
import {connect} from "react-redux";
import ServiceCard from "../services/sections/service-card";
import NewsCard from "../homepage/sections/news/newsCard";
function NewsPage(props) {

    const [isLoading, setIsLoading] = useState(!(!props.newsList.length > 0 ));
    const [news, setNews] = useState(props.newsList.length > 0 ? props.newsList : []);

    const getData = async () => {
        await axios.get(`${serverLink}website/homepage/news/active/${hospitalCode}`).then((result) => {
            const data = result.data;
            if (data.length > 0){
                setNews(data)
                props.setOnNewsDetails(data);
            }
        }).catch((error) => {
            console.log("error", error);
        });
        setIsLoading(false);
    };
    useEffect(() => {
        getData();
    }, [""]);



    return (
        isLoading ? <DataLoader /> :
            <>
                <section id="appointmentPage">
                    <div className="container department-list fade-in-right customWidth ">
                        <div className="row">
                            <header className="header" >
                                <h2>Our Blog</h2>
                                <div className="sub_heading">
                                    <hr />
                                    <p>Recent News</p>
                                    <hr />
                                </div>
                            </header>
                            <div className="newsCardContainer">
                                {
                                    news.length > 0 ? news.map((item, index)=>{
                                            return(
                                                // <NewsCard />
                                                 <NewsCard key={index} _link={item.hospitalCode} _image={item.image} _date={item.submittedOn} _description={item.description} _submittedby={item.submittedBy} _title={item.title}/>
                                            )
                                        })
                                        :
                                        <div className="col-md-12 alert alert-danger">
                                            <h2>No Record Found</h2>
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                </section>
            </>
    )
}
const mapStateToProps = (state) => {
    return {
        newsList: state.NewsDetails,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setOnNewsDetails: (p) => {
            dispatch(setNewsDetails(p));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(NewsPage);
