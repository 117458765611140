import React from "react";
import {Link} from "react-router-dom";
import {encryptData} from "../../../resources/constants";
function DoctorsCard(props) {
    return (
        <>
            <Link to={`/doctors-schedule/${encryptData(props.doctor)}`} >
                <div className="department-1-item col-md-3">
                    <span><img src="/assets/img/baze_university-hospital.png" alt="Doctor" className="avatar" /></span>
                    <h4 className={"caption"}>{props.name ?? "Name"}</h4>
                    <p style={{marginTop: '-10px', fontSize: '1px !important'}}>{props.specialisation ?? "N/A"}</p>
                    <button type="button" className="btn btn-primary">{props.btnName ?? "Read More"}</button>
                </div>
            </Link>
        </>
    )
}
export default DoctorsCard;