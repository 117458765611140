import "./register.css"
import { Link } from "react-router-dom";
import {useEffect, useState} from "react";
import {showAlert} from "../common/sweetalert/sweetalert";
import {toast} from "react-toastify";
import axios from "axios";
import {serverLink, hospitalCode} from "../../resources/url";
import StateData from "../../resources/state_and_lga.json";
import CountryData from "../../resources/country.json";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import SimpleFileUpload from 'react-simple-file-upload';
import {simpleFileUploadAPIKey} from "../../resources/url";
import {FlutterWaveButton, closePaymentModal, useFlutterwave} from 'flutterwave-react-v3';
import PackageJson from '../../../package.json';

function Register() {
	const today = new Date();
	// const dateOfBirth = today.getDate()+'/'+(today.getMonth()+1)+'/'+today.getFullYear();
	// const today = new Date();
	const dateOfBirth = `${today.getFullYear()}-${(today.getMonth()+1) < 10 ? '0'+(today.getMonth()+1) : (today.getMonth()+1)}-${today.getDate() < 10 ? '0'+today.getDate() : today.getDate()}`;


	const [IsLoading, setIsLoading] = useState(false);
	const [stateList, setStateList] = useState([]);
	const [lgaList, setLgaList] = useState([]);
	const [config, setConfig] = useState({
		public_key: 'FLWPUBK_TEST-fec2591a33dfadf0b651dfde847af71a-X',
		// public_key: 'FLWPUBK-32ffa936b7a712d8ac8761b584dad9e7-X',
		tx_ref: "",
		amount: 10000,
		currency: 'NGN',
		payment_options: 'card,mobilemoney,ussd,banktransfer,qr',
		customer: {
			email: '',
			phone_number: '',
			name: '',
		},
		customizations: {
			title: PackageJson.title,
			description: PackageJson.description,
			logo: 'https://bazeuniversityhospital.com/assets/img/logo.png'
		}
	});
	const [formData, setFormData] = useState({
		title: "",
		firstName: "",
		middleName: "",
		surname: "",
		gender: "",
		phoneNumber: "",
		altPhoneNumber: "",
		dateOfBirth: null,
		bloodGroup: "",
		emailAddress: "",
		maritalStatus: "",
		religion: "",
		occupation: "",
		residentialAddress: "",
		status: "",
		nationality: "",
		state: "",
		lga: "",
		ward: "",
		zipCode: "",
		officeAddress: "",
		passport: "",
		submittedBy: "online",
		nextOfKinName: "",
		nextOfKinPhone: "",
		nextOfKinRelationship: "",
		hospitalCode: hospitalCode
	});

	const stateData = () => {
		StateData.map(state => {
			if (state.state !== 'Non-Nigerian')
				setStateList(stateList => [...stateList, state.state])
		});
	}

	const handleSubmit = async (sendData) => {

		toast.info("Submitting your record...");
		axios.post(`${serverLink}patient/add`, sendData)
			.then(result => {
				const response = result.data.message;
				const patient_id = result.data.patient_id;
				if (response === 'user exist') {
					showAlert("Oops!", "A patient with the same email address or phone number already exist", "error");
					setIsLoading(false)
				} else if (response === 'error') {
					showAlert(
						"ERROR",
						"Something went wrong adding the patient. Please try again!",
						"error"
					);
					setIsLoading(false)
				} else if (response === 'success') {
					let paymentData = {
						...sendData,
						patientSerial: patient_id,
					}
					axios.post(`${serverLink}payment/online_registration`, paymentData)
						.then(result => {
							if (result.data.message === 'success') {
								toast.success("Your record is saved successfully. Kindly check your registered email for your login credentials");
								setTimeout(function () {
									window.location.href = '/login';
								}, 3000)
							} else {
								showAlert(
									"ERROR",
									"Something went wrong adding the patient. Please try again!",
									"error"
								);
								setIsLoading(false)
							}
							setIsLoading(false)
						}).catch(error => {
							showAlert(
								"NETWORK ERROR",
								"Please check your network connection and try again!",
								"error"
							);
							setIsLoading(false)
						})
				} else {
					showAlert(
						"ERROR",
						"Your payment was received successfully. But something went wrong updating your record. Kindly email us your evidence of payment",
						"error"
					);
					setIsLoading(false)
				}

			})
			.catch(error => {
				showAlert(
					"NETWORK ERROR",
					"Please check your network connection and try again!",
					"error"
				);
				setIsLoading(false)
			})

	}

	const onEdit = (e) => {
		if (e.target.name === 'nationality') {
			setStateList([])
			setLgaList([])
			setFormData({
				...formData,
				state: "",
			})
			if (e.target.value === 'Nigeria') {
				stateData();
			} else {
				StateData.map(state => {
					if (state.state === 'Non-Nigerian')
						setStateList(stateList => [...stateList, state.state])
						//stateList.push(state.state)
				});
			}
		}

		if(e.target.name === 'state') {
			setLgaList([])
			StateData.map(state => {
				if(state.state === e.target.value) {
					state.lgas.map(lga => {
						setLgaList(lgaList => [...lgaList, lga])
					})
				}
			})
		}

		if (e.target.name === "emailAddress"){

			setConfig({
				...config,
				tx_ref: transactionReferenceGenerator(),
				customer: {
					...config.customer,
					email:  e.target.value,
				}
			})
		}

		if (e.target.name === "phoneNumber"){
			setConfig({
				...config,
				customer: {
					...config.customer,
					phone_number:  e.target.value,
				}
			})
		}

		if (e.target.name === "firstName"){
			setConfig({
				...config,
				customer: {
					...config.customer,
					name:   e.target.value +" "+formData.middleName+" "+formData.surname,
				}
			})
		}

		if (e.target.name === "surname"){
			setConfig({
				...config,
				customer: {
					...config.customer,
					name:   formData.firstName +" "+formData.middleName+" "+e.target.value,
				}
			})
		}

		if (e.target.name === "middleName"){
			setConfig({
				...config,
				customer: {
					...config.customer,
					name:   formData.firstName +" "+e.target.value+" "+formData.surname,
				}
			})
		}

		setFormData({
			...formData,
			[e.target.name]: e.target.value,
		})
	};


	const onImageChange = (event) => {
		if (event !== '') {
			setFormData({
				...formData,
				passport: event,
			})
		}
	};

	const currencyConverter = amount => {
		const formatter = new Intl.NumberFormat('en-US', {
			style: 'currency',
			currency: 'NGN'
		});
		return formatter.format(amount);
	};

	const transactionReferenceGenerator = () => {
		let result = '';
		let characters =
			'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
		let charactersLength = characters.length;
		for (let i = 0; i < 16; i++) {
			result += characters.charAt(Math.floor(Math.random() * charactersLength));
		}
		return result;
	}

	const handleFlutterPayment = useFlutterwave(config);


	const makePayment = () => {

		if(formData.title === "") {
			showAlert("Empty Field", "Please Select the Patient Title", "error");
			return false;
		}

		if(formData.firstName === "") {
			showAlert("Empty Field", "Please Select the Patient First Name", "error");
			return false;
		}

		if(formData.surname === "") {
			showAlert("Empty Field", "Please Enter the Patient Surname", "error");
			return false;
		}

		if(formData.phoneNumber === "") {
			showAlert("Empty Field", "Please Enter the Patient Phone Number", "error");
			return false;
		}

		if(formData.gender === "") {
			showAlert("Empty Field", "Please Select the Patient Gender", "error");
			return false;
		}

		if(formData.bloodGroup === "") {
			showAlert("Empty Field", "Please Select the Patient Blood Group", "error");
			return false;
		}



		if(formData.dateOfBirth === "" ||  formData.dateOfBirth === null) {
			showAlert("Empty Field", "Please Select the Patient Date of Birth", "error");
			return false;
		}


		if(formData.maritalStatus === "") {
			showAlert("Empty Field", "Please Select the Patient Marital Status", "error");
			return false;
		}

		if(formData.nextOfKinName === "") {
			showAlert("Empty Field", "Please Enter the Patient Next of Kin Name", "error");
			return false;
		}

		if(formData.nextOfKinPhone === "") {
			showAlert("Empty Field", "Please Enter the Patient Next of Kin Phone Number", "error");
			return false;
		}

		if(formData.nextOfKinRelationship === "") {
			showAlert("Empty Field", "Please Select the Patient Next of Kin Relationship", "error");
			return false;
		}


		if(formData.nationality === "") {
			showAlert("Empty Field", "Please Select Nationality", "error");
			return false;
		}

		if(formData.state === "") {
			showAlert("Empty Field", "Please Select the Patient State", "error");
			return false;
		}

		if(formData.lga === "") {
			showAlert("Empty Field", "Please Select the Patient LGA", "error");
			return false;
		}

		if(formData.religion === "") {
			showAlert("Empty Field", "Please Select the Patient Religion", "error");
			return false;
		}


		if(formData.residentialAddress === "") {
			showAlert("Empty Field", "Please Select the Patient Residential Address", "error");
			return false;
		}
		setIsLoading(true)

		handleFlutterPayment({
			callback: (response) => {
				if (response.status === 'successful') {
					const sendData = {
						...formData,
						patientSerial: "",
						patientFullName: response.customer.name,
						paymentMethod: 'Online',
						paymentReference: response.transaction_id,
						paymentAmount: response.amount,
						paymentStatus: response.status,
						paymentType: 'Registration',
						submittedBy: 'Patient'
					}
					handleSubmit(sendData);

				}else {
					showAlert(
						"PAYMENT ERROR",
						`Something went wrong with your payment as it returned the following response: ${response.status}`,
						"error"
					);
				}
				closePaymentModal();
			},
			onClose: () => {
				setIsLoading(false)
			}
		});

	}

	return (
		<section id="registerPage">
			<div className="register-card-container">
				<div className="register-card-title">
					<h2>Register</h2>
					{/*<p>Hi, Welcome to Baze University Hospital</p>*/}
				</div>

				<div className="register-card-subtitle" style={{marginLeft: '80px', marginRight: '50px'}}>
					<hr />
					<p>Please enter your details to register</p>
					<hr />
				</div>

				<form>
					<div className="register-credentials-container">

						{/* PERSONAL INFORMATION */}
						<fieldset id="personal-info">
							<legend>Personal Information</legend>

							<div className="split-fields">
								<div className="input-field">
									<label>Title</label>
									{/* <input type="text" name="title" placeholder="Alaye | Sir | Madam | Mr | Mrs | Miss | Dr | Prof | Bro | Sis" /> */}
									<select
										name="title"
										className="form-control"
										id="title"
										value={formData.title}
										onChange={onEdit}>

										<option value="">Select Title</option>
										<option>Mr</option>
										<option>Mrs</option>
										<option>Mss</option>
										<option>Ms</option>
										<option>Dr</option>
										<option>Capt</option>
										<option>Prof</option>
										<option>HRH</option>
									</select>
								</div>
								<div className="input-field">
									<label>First Name</label>
									<input name="firstName"
										   type="text"
										   className="form-control"
										   id="firstName"
										   value={formData.firstName}
										   onChange={onEdit}
										   placeholder="First Name" />
								</div>
							</div>

							<div className="split-fields">
								<div className="input-field">
									<label>Middle Name</label>
									<input name="middleName"
										   type="text"
										   className="form-control"
										   id="middleName"
										   value={formData.middleName}
										   onChange={onEdit}
										   placeholder="Middle Name" />
								</div>
								<div className="input-field">
									<label>Surname</label>
									<input name="surname"
										   type="text"
										   className="form-control"
										   id="surname"
										   value={formData.surname}
										   onChange={onEdit}
										   placeholder="Surname" />
								</div>
							</div>

							<div className="input-field">
								<label>Email</label>
								<input name="emailAddress"
									   type="email"
									   className="form-control"
									   id="emailAddress"
									   value={formData.emailAddress}
									   onChange={onEdit}
									   placeholder="YourEmail@MailProvider.com" />
							</div>
						</fieldset>


						{/* CONTACT | METADATA INFO */}
						<fieldset id="metadata">
							<legend>Metadata</legend>


							<div className="split-fields">
								<div className="input-field">
									<label>Phone Number</label>
									<input name="phoneNumber"
										   type="text"
										   className="form-control"
										   id="phoneNumber"
										   value={formData.phoneNumber}
										   onChange={onEdit}
										   placeholder="+234 XXXX XXXX" />
								</div>
								<div className="input-field">
									<label>Alt Phone Number</label>
									<input  name="altPhoneNumber"
											type="text"
											className="form-control"
											id="altPhoneNumber"
											value={formData.altPhoneNumber}
											onChange={onEdit} placeholder="+234 XXXX XXXX" />
								</div>
							</div>

							<div className="split-fields">
								<div className="input-field">
									<label>Gender</label>
									{/* <input type="select" name="gender" placeholder="Male | Female | Queer | Rainbow | LGBTQ | QWERTYUIOP" /> */}
									<select  name="gender"
											 className="form-control"
											 id="gender"
											 value={formData.gender}
											 onChange={onEdit}>
										<option value="">What's your gender?</option>
										<option>Male</option>
										<option>Female</option>
									</select>
								</div>
								<div className="input-field">
									<label>Blood Group</label>
									{/* <input type="select" name="blood_group" placeholder="-A | +A | -B | +B | -AB | +AB | -O | +O " /> */}
									<select  name="bloodGroup"
											 type="text"
											 className="form-control"
											 id="bloodGroup"
											 value={formData.bloodGroup}
											 onChange={onEdit}>
										<option value="">What's your blood group?</option>
										<option>A+</option>
										<option>A-</option>
										<option>AB+</option>
										<option>AB-</option>
										<option>B+</option>
										<option>B-</option>
										<option>O+</option>
										<option>O-</option>
										<option>N/A</option>
									</select>
								</div>
							</div>
							<div className="split-fields">
								<div className="input-field">
									<label>Date Of Birth</label>
									<input type="date"
										   name="dateOfBirth"
										   id="dateOfBirth"
										   className="  form-control"
										   value={formData.dateOfBirth}
										   onChange={onEdit}
									/>

								</div>
								<div className="input-field">
									<label>Marital Status</label>
									{/* <input type="text" name="marital_status" placeholder="Marital Status" /> */}
									<select  name="maritalStatus"
											 className="form-control"
											 id="maritalStatus"
											 value={formData.maritalStatus}
											 onChange={onEdit}>
										<option value="">Select Marital Status</option>
										<option>Single</option>
										<option>Married</option>
										<option>Divorced</option>
										<option>Widowed</option>
										<option>Separated</option>
									</select>
								</div>
							</div>

						</fieldset>


						{/* NEXT OF KIN */}
						<fieldset>
							<legend>Next Of Kin Info</legend>

							<div className="input-field">
								<label>Next of Kin Full Name</label>
								<input type="text"
									   name="nextOfKinName"
									   className="form-control"
									   id="nextOfKinName"
									   value={formData.nextOfKinName}
									   onChange={onEdit}
									   placeholder="Full name of your next of kin" />
							</div>

							<div className="split-fields">
								<div className="input-field">
									<label>Next of Kin Phone Number</label>
									<input type="text"
										   name="nextOfKinPhone"
										   className="form-control"
										   id="nextOfKinPhone"
										   value={formData.nextOfKinPhone}
										   onChange={onEdit} placeholder="+234 XXXX XXXX" />
								</div>
								<div className="input-field">
									<label>Relationship with Next of Kin</label>
									<select
										name="nextOfKinRelationship"
										className="form-control"
										id="nextOfKinRelationship"
										value={formData.nextOfKinRelationship}
										onChange={onEdit}

									>
										<option/>
										<option value="">Select Relationship</option>
										<option>Father</option>
										<option>Mother</option>
										<option>Husband</option>
										<option>Wife</option>
										<option>Brother</option>
										<option>Sister</option>
										<option>Son</option>
										<option>Daughter</option>
									</select>
								</div>
							</div>

						</fieldset>


						{/* DEMOGRAPHICS | ADDRESS */}
						<fieldset>
							<legend>Demographics Information</legend>

							<div className="split-fields">
								<div className="input-field">
									<label>Nationality</label>
									<select
										name="nationality"
										id="nationality"
										className="form-control"
										value={formData.nationality}
										onChange={onEdit}>
										<option/>
										{
											CountryData.map((country, index) => {
												return <option key={index} value={country}>{country}</option>
											})
										}
									</select>
								</div>
								<div className="input-field">
									<label>State Of Origin</label>
									<select
										name="state"
										id="state"
										className="form-control"
										value={formData.state}
										onChange={onEdit}>
										<option/>
										{
											stateList.map((state, index) => {
												return <option key={index} value={state}>{state}</option>
											})
										}
									</select>
								</div>
							</div>

							<div className="split-fields">
								<div className="input-field">
									<label>LGA</label>
									<select
										name="lga"
										id="lga"
										className="form-control"
										value={formData.lga}
										onChange={onEdit}>
										<option/>
										{
											lgaList.map((lga, index) => {
												return <option key={index} value={lga}>{lga}</option>
											})
										}
									</select>
								</div>
								<div className="input-field">
									<label>Zip Code</label>
									<input  name="zipCode"
											type="text"
											className="form-control"
											id="zipCode"
											value={formData.zipCode}
											onChange={onEdit}
											placeholder="Zip Code" />
								</div>
							</div>

							<div className="split-fields">
								<div className="input-field">
									<label>Religion</label>
									{/* <input type="text" name="religion" placeholder="Buddhism | Jewish | Atheist | Ekankar | etc." /> */}
									<select
										name="religion"
										className="form-control"
										id="religion"
										value={formData.religion}
										onChange={onEdit}

									>
										<option/>
										<option value="">Select Religion</option>
										<option>Christianity</option>
										<option>Islam</option>
										<option>Others</option>
									</select>
								</div>
								<div className="input-field">
									<label>Ward</label>
									<input name="ward"
										   type="text"
										   className="form-control"
										   id="ward"
										   value={formData.ward}
										   onChange={onEdit}
										   placeholder="Ward" />
								</div>
							</div>

							<div className="input-field">
								<label>Residential Address</label>
								<textarea rows={3}
										  name="residentialAddress"
										  type="text"
										  className="form-control"
										  id="residentialAddress"
										  value={formData.residentialAddress}
										  onChange={onEdit}
										  placeholder="Address of where you live now" />
							</div>

							<div className="input-field">
								<label>Occupation</label>
								<input type="text"
									   name="occupation"
									   className="form-control"
									   id="occupation"
									   value={formData.occupation}
									   onChange={onEdit}
									   placeholder="What do you do for a living? " />
							</div>

							<div className="input-field">
								<label>Work Place Address</label>
								<textarea rows={5} name="officeAddress"
										  className="form-control"
										  id="officeAddress"
										  defaultValue={formData.officeAddress}
										  onChange={onEdit}
										  name="officeAddress"
										  placeholder="Address of where you work." />
							</div>
							<div className="input-field">
								<label> Passport Upload</label>
								<SimpleFileUpload
									apiKey={simpleFileUploadAPIKey}
									tag="buh-patient-passport"
									onSuccess={onImageChange}
									preview="true"
									width="100%"
									// height="100"
									accepted="image/*"
									maxFileSize={1}
									buttonClass="upload-button"
									buttonText="Upload Images"
								/>
								<span className="badge bg-danger mt-2">Max of 1MB file is allowed!</span>
							</div>
						</fieldset>

					</div>

					{
						IsLoading ?
							<div className="login-cardBtn mb-3" disabled>
								<div><span> Please wait...</span></div>
							</div>
							:
							 <button type="button"  onClick={makePayment} className="register-cardBtn"> <h3>Make Payment</h3> </button>


					}



					<div className="register-card-bottom-content">
						<div className="register-to-createAccount">
							<p>Already registered? </p>
							<Link to="/login">
								<p>Login Here ←</p>
							</Link>
						</div>
					</div>

				</form>
			</div>
		</section>
	)
}

export default Register