import { Link } from "react-router-dom"
import { formatDateAndTime } from "../../../../resources/constants"

function NewsCard({ _image, _title, _submittedby, _date, _description}) {
	return (
		<article className="newsCard newsContentCard" >
			<Link to={`/news/${_title}`} >
			<figure>
					<img src={_image ?? "/assets/img/baze_university-hospital.png"} alt="Baze University Hospital" style={{maxHeight: '200px', maxWidth: '300px', minHeight: '190px', minWidth: '100%'}} />
				</figure>
				<p className="text-dark h5" style={{ paddingLeft: '10px', marginTop: '10px'}}>
					{_title || "Baze university hospital to be commissioned Baze university hospital to be commissioned.."}
				</p>
			</Link>
			<figcaption style={{textAlign:"left", paddingLeft: '10px'}}>
				<img src="/assets/img/news-calender-icon.png" alt="calendar icon" /> <small>{formatDateAndTime(_date, "date") || "10th November, 2022"}</small>
			</figcaption>
			<Link style={{ paddingLeft: '10px',}} to={`/news/${_title}`} > Read More </Link>
</article>
	)
}

export default NewsCard