import {hospitalCode, serverLink} from "./url";
import axios from "axios";

const CryptoJS = require("crypto-js");

export const projectAddress = "Kano, Nigeria.";
export const projectTwitter = " https://twitter.com/";
export const projectFacebook = "https://www.facebook.com/";
export const projectYoutube = "https://www.youtube.com/";


export const formatDateAndTime = (date, option) => {
  if (date !== null) {
    const user_date = new Date(date);
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const monthNamesShort = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const day =
      user_date.getDate() < 10
        ? "0" + user_date.getDate()
        : user_date.getDate();
    const hour =
      user_date.getHours() < 10
        ? "0" + user_date.getHours()
        : user_date.getHours();
    const min =
      user_date.getMinutes() < 10
        ? "0" + user_date.getMinutes()
        : user_date.getMinutes();
    const sec =
      user_date.getSeconds() < 10
        ? "0" + user_date.getSeconds()
        : user_date.getSeconds();

    let date_string = "";
    if (option === "date_and_time")
      date_string = `${day}-${
        monthNames[user_date.getMonth()]
      }-${user_date.getFullYear()} : ${hour}:${min}:${sec}`;
    else if (option === "date")
      date_string = `${day}-${
        monthNames[user_date.getMonth()]
      }-${user_date.getFullYear()}`;
    else if (option === "day") date_string = day;
    else if (option === "full_month")
      date_string = monthNames[user_date.getMonth()];
    else if (option === "short_month")
      date_string = monthNamesShort[user_date.getMonth()];
    else if (option === "year_only") date_string = user_date.getFullYear();

    return date_string;
  } else {
    return "--";
  }
};

export const formatDate = (date) => {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

export const currencyConverter = (amount) => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "NGN",
  });
  return formatter.format(amount);
};


export function encryptData(string, val = false) {
  let secret_key =
    val === false
      ? "HOSPITAL_MANAGEMENT_SYSTEM_ENCRYPT"
      : hospitalCode;
  let secret_iv =
    val === false
      ? "HOSPITAL_MANAGEMENT_SYSTEM_ENCRYPT_IV"
      : hospitalCode;
  // hash
  let kee = CryptoJS.SHA256(secret_key).toString().substr(0, 32);
  let ivv = CryptoJS.SHA256(secret_iv).toString().substr(0, 16);

  kee = CryptoJS.enc.Utf8.parse(kee);
  ivv = CryptoJS.enc.Utf8.parse(ivv);

  let encrypted = CryptoJS.AES.encrypt(string, kee, { iv: ivv });

  let result = encrypted.toString();
  return btoa(result);
}

export function decryptData(string, val = false) {
  let secret_key =
    val === false
      ? "HOSPITAL_MANAGEMENT_SYSTEM_ENCRYPT"
      : hospitalCode;
  let secret_iv =
    val === false
      ? "HOSPITAL_MANAGEMENT_SYSTEM_ENCRYPT_IV"
      : hospitalCode;
  // hash
  let kee = CryptoJS.SHA256(secret_key).toString().substr(0, 32);
  let ivv = CryptoJS.SHA256(secret_iv).toString().substr(0, 16);

  kee = CryptoJS.enc.Utf8.parse(kee);
  ivv = CryptoJS.enc.Utf8.parse(ivv);

  var decrypted = CryptoJS.AES.decrypt(atob(string), kee, { iv: ivv });

  return decrypted.toString(CryptoJS.enc.Utf8);
}

export function shuffle(originalArray) {
  var array = [].concat(originalArray);
  var currentIndex = array.length, temporaryValue, randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}
