// import { Link } from "react-router-dom";

import ServiceCard from "./serviceCard";

import "./service.css"
import {Link} from "react-router-dom";


export default function Service(props) {
    return (
        <>
            
            <div className="serviceContainer">

                <div className="sectionHeader">
                    <p className="sectionIntro">- What we Serve</p>
                    <div className="sectionBody">
                        <h1 className="sectionBodyTitle"><span>Our</span> Services</h1>
                        <p className="sectionBodyNote">We offer quality and roburts health services</p>
                    </div>
                </div>

                <div className="container">
                    <div className="serviceContainerCards">
                        {
                            props.serviceList.length > 0 && props.serviceList.splice(0, 8).map((item, index) => {
                                return (
                                    <ServiceCard key={index} title={item.serviceName} description={item.serviceDescription} />
                                );
                            })
                        }
                    </div>
                </div>

                <div className="allPlainBtn">
                    
                    <Link to="/services">
                        <div>
                            
                            <p>More Services Offered</p>
                        </div>
                    </Link>
                </div>
            </div>

        </>
    );
}