import "./login.css"
import { Link } from "react-router-dom";
import {useState} from "react";
import axios from "axios";
import {serverLink} from "../../resources/url";
import {toast} from "react-toastify";
import {showAlert} from "../common/sweetalert/sweetalert";
import CryptoJS from "crypto-js";
import DataLoader from "../common/dataLoader/dataLoader";


function Login() {
	const [IsLoading, setIsLoading] = useState(false);
	const [formData, setFormData] = useState({
		username: "",
		password: "",
	});

	const handleSubmit = async (e) => {
		e.preventDefault();
		if(formData.username === "") {
			showAlert("Empty Field", "Please enter your username", "error");
			return false;
		}

		if(formData.password === "") {
			showAlert("Empty Field", "Please enter your password", "error");
			return false;
		}

		const sendData = {
			...formData
		}

		toast.info("Login.... Please wait");
		setIsLoading(true)
		axios.post(`${serverLink}patient/login`, sendData)
			.then(result => {
				const response = result.data.message;

				if (response === 'not exist') {
					showAlert("INVALID LOGIN CREDENTIAL", "Your account doesn't exist on our platform. Kindly check your credential and try again", "error");
				} else if (response === 'error') {
					showAlert(
						"ERROR",
						"Something went wrong. Please try again!",
						"error"
					);
				} else if (response === 'invalid password') {
					showAlert("INVALID PASSWORD", "Invalid password. Kindly check your credential and try again", "error");
				} else {
					let fullName = `${result.data.user.firstName} ${result.data.user.middleName} ${result.data.user.surname}`;
					let patientSerial = result.data.user.patientSerial;
					let patientPassport = result.data.user.passport;
					let hospitalCode = result.data.user.hospitalCode;

					localStorage.setItem('patientFullName', CryptoJS.AES.encrypt(fullName, 'BazeUniversityHospitalPatientDashboard'));
					localStorage.setItem('patientSerial', CryptoJS.AES.encrypt(patientSerial, 'BazeUniversityHospitalPatientDashboard'));
					localStorage.setItem('patientPassport', CryptoJS.AES.encrypt(patientPassport, 'BazeUniversityHospitalPatientDashboard'));
					localStorage.setItem('hospitalCode', CryptoJS.AES.encrypt(hospitalCode, 'BazeUniversityHospitalPatientDashboard'));
					localStorage.setItem('patientToken', result.data.token);
					toast.success("Login Successful. Please wait while the system is redirecting ...");

					setTimeout(function () {
						window.location.href = 'https://dashboard.bazeuniversityhospital.com/parameters.php?name='+localStorage.getItem('patientFullName')+'&serial='+localStorage.getItem('patientSerial')+'&passport=""'+'&hospital='+localStorage.getItem('hospitalCode')+'&token='+localStorage.getItem('patientToken')
					})
				}
				setIsLoading(false)
			})
			.catch(error => {
				showAlert(
					"NETWORK ERROR",
					"Please check your network connection and try again!",
					"error"
				);
				setIsLoading(false)
			})

	}

	const handleChange = (e) => {
		setFormData({
			...formData,
			[e.target.name]: e.target.value,
		})

	}

	return (
		<section id="loginPage">
			{/* <main>
				Login Page.
			</main> */}

			<div className="login-card-container">
				<div className="login-card-title">
					<h2>Login</h2>
					<p>Hi, Welcome back</p>
				</div>

				<div className="login-card-subtitle">
					<hr />
					<p>Please enter your details to login</p>
					<hr />
				</div>

				<div className="login-credentials-container">
					<div className="patient-ID">
						<p>Patient ID</p>
						<input type="text" className="form-control" name="username" id="username"  onChange={handleChange} placeholder="please enter your patient ID here..." />

					</div>
					<div className="patient-password">
						<div>
							<p>Password</p>
							<Link to="/">
								<p>Forgot Password?</p>
							</Link>
						</div>
						<input  type="password" className="form-control" name="password" id="password"  onChange={handleChange} placeholder="please enter your password here..." />
					</div>

				</div>

				<div className="login-card-bottom-content">
					{
						IsLoading ?
							<div className="login-cardBtn" disabled>
								<div><span> Please wait...</span></div>
							</div>
							:
							<div className="login-cardBtn" onClick={handleSubmit}>
								<div>
									<p>Login</p>
								</div>

							</div>
					}

					<div className="login-to-createAccount">
						<p>Not registered yet? </p>
						<Link to="/register">
							<p>Create an Account ←</p>
						</Link>
					</div>

				</div>

			</div>
		</section>
	)
}

export default Login