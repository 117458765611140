import React from 'react'
import "./appointment.css"
import { Link } from "react-router-dom";


export default function appointment() {
    return (
        <>
        <div className='appointment-bg-container'>
            <div className='appointment-container'>

                <div className="sectionHeader">
                    <p className="sectionIntro">- Contact Us</p>
                    <div className="sectionBody">
                        <h1 className="sectionBodyTitle"><span>Make</span> An Appointment</h1>
                    </div>
                </div>


                <div className='appointment-content'>
                    <div className='appointment-left-content'>
                        <h1>Find and <span>Meet</span> with a Doctor at a time conveinient for you</h1>
                        <p>Take advantage of app to book an appointment with the best doctors for you and your family.</p>

                        <div className="allImagedBtn">
                            <Link to="/login">
                                <div>
                                    <img src="assets\img\makeAppointTreatmentW.png" alt="logo" />
                                    <p>Request An Appointment</p>
                                </div>
                            </Link>
                        </div>


                    </div>

                    <div className='appointment-right-content'>
                        <img src="assets\img\appoint-right-img.png" alt="" />
                    </div>

                </div>

            </div>

        </div>
        </>
    )
}
