import React from "react";
import {Link} from "react-router-dom";
function ServiceCard(props) {
    return (
        <>
            <Link to={`/service/${props.name}`}>
                <div className="department-1-item col-md-3">
                    <span><i className={`${props.icon ?? ""} text-secondary`}/></span>
                    <h4>{props.name ?? "Name"}</h4>
                    <p>{props.desc ?? "N/A"}</p>
                    <button type="button" className="btn btn-secondary btn-sm">{props.btnName ?? "Read More"}</button>
                </div>
            </Link>
        </>
    )
}
export default ServiceCard;