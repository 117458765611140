import React, {useEffect, useState} from "react";
import "../doctor-schedule.css";
import { useNavigate ,useParams} from "react-router-dom";
import axios from "axios";
import {hospitalCode, serverLink} from "../../../resources/url";
import DataLoader from "../../common/dataLoader/dataLoader";
import {decryptData} from "../../../resources/constants";

function ScheduleView() {
    const { slug } = useParams();
    if (slug === "") window.href = '/';
    const doctor = decryptData(slug)

    const navigate = useNavigate();


    const [isLoading, setIsLoading] = useState(true);
    const [doctorData, setDoctorData] = useState("");
    const [doctorSchedule, setDoctorSchedule] = useState([]);
    const [schedules, setSchedules] = useState({
        monday: {},
        tuesday: {},
        wednesday: {},
        thursday: {},
        friday: {},
        saturday: {},
        sunday: {}
    });
    const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']

    const getData = async () => {
        await axios.get(`${serverLink}doctor_schedule/website/${hospitalCode}/${doctor}`).then((result) => {
            const data = result.data;
            if (data?.scheduleData.length > 0){
                setDoctorData(data.doctorName)
                setDoctorSchedule(data.scheduleData)
                setSchedules({
                    ...schedules,
                    ...data.scheduleData[0]
                })
            }
        }).catch((error) => {
            console.log("error", error);
        });
        setIsLoading(false);
    };
    useEffect(() => {
        getData();
    }, [""]);


    return (isLoading ? <DataLoader /> :
            <>
                <section id="appointmentPage">
                    <div className="container department-list fade-in-right customWidth ">
                        <div className="row">
                            <a  href="javascript: history.go(-1)">
                            <div className="book_appointment_backArrow">
                                <img src="/assets/img/BackArrow.png" alt="back button" />
                                <p>Back</p>
                            </div>
                            </a>
                            <header className="header" >
                                <h2>Doctor Schedule</h2>
                                <div className="sub_heading">
                                    <hr />
                                    <p>{doctorData !== '' ? ` ${doctorData}` : ''}</p>
                                    <hr />
                                </div>
                            </header>
                            <div className="our-services department-1-col-3">
                                {
                                    doctorSchedule.length > 0 ?
                                        <table className="table table-striped" style={{textAlign: 'left'}}>
                                            <thead>
                                            <tr>
                                                <th>Day</th>
                                                <th>Duration</th>
                                                <th>Start</th>
                                                <th>End</th>
                                                <th>Slots</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                days.map((day, index) => {
                                                    return (
                                                        <tr key={(index + 1)} id={(index + 1)}>
                                                            <td className="text-capitalize">{day}</td>
                                                            <td>{`${!schedules[day].duration ? "--" : schedules[day].duration.length > 1 ?  schedules[day].duration + "hours" : schedules[day].duration + "mins"}`}</td>
                                                            <td>{`${!schedules[day].startTime  ? "--" : schedules[day].startTime}`}</td>
                                                            <td>{`${!schedules[day].endTime  ? "--" : schedules[day].endTime}`}</td>
                                                            <td>
                                                                {
                                                                 !schedules[day].slots ? "--" :
                                                                     schedules[day].slots.length > 0 && schedules[day].slots.map((item, i)=>{
                                                                         let color = item.status === 'available' ? 'badge bg-primary' : 'badge bg-danger';
                                                                         return (<div key={i}>
                                                                            <span key={i} className={`${color}`}>{item.slot}</span>
                                                                             { Number.isInteger((i+1) / 5) ? <br/> : '' }
                                                                         </div>)
                                                                     })
                                                                }
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }

                                            </tbody>

                                        </table>
                                        :
                                        <div className="col-md-12 alert alert-danger">
                                            <h4>No available schedule for the selected doctor</h4>
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                </section>
            </>
    )
}
export default ScheduleView;