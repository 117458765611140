import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter as Routers, Route, Routes } from "react-router-dom";
import NavigationBar from "../common/navigation/navigationbar";
import TopBar from "../common/navigation/topbar";
import Homepage from "../homepage/homepage";
import NotFound from "../404notfound/404notfound";
import BottomBar from "../common/footer/bottomBar";
import Login from "../login/login";
import Register from "../register/register";
import BookAnAppointment from "../book-an-appointment/bookAnAppointment";
import DoctorSchedule from "../doctor-schedules/doctor-schedule";
import DoctorsView from "../doctor-schedules/doctors-view/doctors-view";
import ScheduleView from "../doctor-schedules/schedule-view/schedule-view";
import ServicesPage from "../services/sevices";
import ServiceDetails from "../services/service-details";
import NewsPage from "../news/news";
import DoctorsList from "../doctors/doctors";
import DoctorsDetails from "../doctors/doctors-details";
import NewsDetails from "../homepage/sections/news/newsDetails";
import PackageJson from '../../../package.json';

export default function PageRoutes() {
    return (
        <Routers>
            <TopBar />
            <NavigationBar />

            <Routes>
                <Route path={"/doctors"} element={<DoctorsList />} />
                <Route path={"/doctors/:slug"} element={<DoctorsDetails />} />
                <Route path={"/"} element={<Homepage />} />
                <Route path={"/login"} element={<Login />} />
                <Route path={"/register"} element={<Register />} />
                <Route path={"/book-an-appointment"} element={<BookAnAppointment />} />
                <Route path={"/schedules"} element={<DoctorSchedule />} />
                <Route path={"/services"} element={<ServicesPage />} />
                <Route path={"/service/:slug"} element={<ServiceDetails />} />
                <Route path={"/doctors-list/:slug"} element={<DoctorsView />} />
                <Route path={"/doctors-schedule/:slug"} element={<ScheduleView />} />
                <Route path={"/news"} element={<NewsPage />} />
                <Route path={"/news/:slug"} element={<NewsDetails />} />
                <Route path={"*"} element={<NotFound />} />

            </Routes>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <ToastContainer />

            <BottomBar />
        </Routers>
    );



}