import { Link } from "react-router-dom"
import { formatDateAndTime } from "../../../../resources/constants";

function Sidebar(props) {
    return (
        <article className="">
            <div className="col-md-11">
                <form className="offset-1 mt-2">
                    <input type="text" name="q" className="form-control" placeholder="Search" onChange={props.handleSearch} autoComplete="off" />
                    <a><i className="fa fa-search text-white" /></a>
                </form>
            </div>
            {
                props.newsList.length > 0 ? (
                    <>
                        <figcaption className={"mb-3"} style={{ textAlign: "justify" }}>
                            <h5>Related News</h5>
                        </figcaption>

                        {props.newsList1.sort((a, b) => a.submittedOn - b.submittedOn).slice(0, 5)
                            .sort(() => Math.random() - 0.5)
                            .map((items, key) => {
                                return (
                                    <>
                                    <Link to={`/news/${items.title}`}>
                                        <div className={"row mb-1"}>
                                            <div className={"col-md-4"}>
                                                <figure>
                                                    <img src={items.image} alt="Baze University Hospital" style={{ maxHeight: '380px', width: '100%', height: '100%', minHeight: '100%' }} />
                                                    {/* <img src={"/assets/img/baze_university-hospital.png"} alt="Baze University Hospital" style={{ maxHeight: '380px', width: '100%' }} /> */}
                                                </figure>
                                            </div>
                                            <div className={"col-md-8"} style={{textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap'}}>
                                                <p className="text-dark h5" style={{fontSize: '14px',  textAlign: "left", padding: '0px'}}>
                                                    {items.title}
                                                </p>
                                                <figcaption className="mb-1 text-dark" style={{ textAlign: "justify", fontSize: '13px', marginTop: '-5px' }}>
                                                    <img src="/assets/img/news-calender-icon.png" alt="calendar icon" /> <small>{formatDateAndTime(items.submittedOn, "date")}</small>
                                                </figcaption>
                                            </div>
                                        </div>
                                        </Link>

                                        <hr/>
                                        </>
                                );
                            })}

                    </>) : ("")
            }

        </article>
    )
}

export default Sidebar