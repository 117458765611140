import { Link } from "react-router-dom";
import "./footer.css";



export default function Footer() {
  return (
    <>
      <footer id="footerID" className="homepageFooter">

        <section className="contactInfo">
          <div className="navLogoContainer">
            <Link className="bottomLogo" to="/">
              <img src="assets\img\BUH Logo.png" alt="logo" />
              <div>
                <p className="navLogoTitle">Baze University</p>
                <p className="navLogoSubTitle">Hospital</p>
              </div>
            </Link>
          </div>

          <p>We offer diverse and robust health services.</p>

          <section className="contacts">
            <address>
              <img src="/assets/img/phone-icon.png" alt="" />
              <small>(+234) 813 376 9658</small>
            </address>

            <address>
              <img src="/assets/img/envelope-icon.png" alt="" />
              <small>info@bazeuniversityhospital.com</small>
            </address>

            <address>
              <img src="/assets/img/pin_location-icon.png" alt="" />
              <small>Plot 606 Cadastral Zone, Behind National Judicial Institute Abuja, Nigeria</small>
            </address>
          </section>

          <section className="socials">
            <p>Our Social Handles</p>
            <address>
              <a href="http://facebook.com">
                <img src="/assets/img/facebook-icon.png" alt="Facebook icon" />
              </a >
              <a href="http://instagram.com">
                <img src="/assets/img/instagram-icon.png" alt="Facebook icon" />
              </a>
              <a href="http://twitter.com">
                <img src="/assets/img/twitter-icon.png" alt="Facebook icon" />
              </a>
            </address>
          </section>

        </section>


        {/* LINK SECTION */}
        <section className="linkSection">

          <section>
            <h2>Opening Hours</h2>
            <Link to="/">Monday - Friday  - 24Hrs </Link>
            <Link to="/"> Saturday- Sunday: 24Hrs </Link>
            <Link to="/"> Public Holidays: 24Hrs </Link>
            <Link to="/"> Emergency: 24Hrs </Link>
          </section>

          <section>
            <h2>About Us</h2>
            <Link to="/about"> About Us </Link>
            <Link to="/news"> News </Link>
            <Link to="/contact"> Contact Us </Link>
          </section>

          <section>
            <h2>Services</h2>
            <Link to="/"> Orthopaedics </Link>
            <Link to="/"> Primary Care </Link>
            <Link to="/"> Dentist </Link>
            <Link to="/"> Surgery </Link>
          </section>

          <section>
            <h2>Links</h2>
            <Link to="/services"> Services </Link>
            <Link to="/news"> News </Link>
            <Link to="/login"> Appointment </Link>
            <Link to="/services"> Surgery </Link>
          </section>


        </section>

        <p><a href="https://staff.bazeuniversityhospital.com/" id="staffLoginBtn" target="_blank">Staff login</a></p>
      </footer>

    </>
  );
}