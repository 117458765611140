import React from 'react'
import {Link} from "react-router-dom";
// import { Link } from "react-router-dom";


export default function serviceCard(props) {
    return (
        <Link to={`/service/${props.title}`}>
            <div className='serviceCardContainer'>
                <div className='serviceCardTop'>
                    <img src="/assets/img/serviceSurgeonG.png" alt="" />
                    <h3>{props.title}</h3>
                </div>
                <div className='serviceCardMid'>
                    <p>Our team of experts specializes in treatments to help you get back to your normal activities.</p>
                    {/* <Link to="/">
                    <div className='serviceCardBottom'>
                        <p>Learn More</p>
                        <img src="/assets/img/RightArrowG.png" alt="right arrow icon" />
                    </div>

                </Link> */}
                </div>
            </div>
        </Link>
    )
}
